.Toastify__toast-container {
  top: 5rem !important;
  width: 450px !important;
  z-index: 70 !important;
}

.Toastify__toast--default {
  border-radius: 6px !important;
  border: 1px solid var(--fg-contrast-low) !important;
  background-color: var(--pop-out) !important;
  padding: 0.5rem 1rem 0.5rem 0.5rem !important;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25) !important;
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 100% !important;
  }
  .Toastify__toast--default {
    width: 96% !important;
    margin: 0 2% !important;
  }
}
