.rc-slider-step {
  @apply cursor-pointer;
}

.rc-slider-rail {
  background-color: var(--fg-contrast-low);
}

.rc-slider.dragging .rc-slider-handle {
  transition: all 0.25s ease-out 0s;
}
/* Reduce choppiness */
.rc-slider.dragging .rc-slider-track {
  transition: width 0.25s ease-out 0s;
}

.rc-slider-track,
.rc-slider-rail {
  height: 2px;
}

.rc-slider-handle {
  border-width: 2px;
  transition: transform 0.25s ease-in;
  border-color: var(--sw-selected);
}

.rc-slider-handle:active {
  box-shadow: none;
  /* Add scale to the translateX transform already included in the package css */
  transform: translateX(-50%) scale(1.3) !important;
  transition: transform 0.25s ease-out;
}

.rc-slider-handle-click-focused:focus,
.rc-slider-handle:active,
.rc-slider-handle:hover {
  border-color: var(--sw-selected);
}

.rc-slider-disabled {
  cursor: not-allowed;
}

/* Set these props to get this bg
      className='color'
      marks={marks}
      step={null}
      trackStyle={{
        background: 'none',
      }}
*/
/* .color .rc-slider-rail {
  background: linear-gradient(
    90deg,
    rgba(244, 4, 19, 1) 0%,
    rgba(246, 141, 7, 1) 35%,
    rgba(0, 255, 6, 1) 100%
  );
} */
