.room-nav-box {
  @apply py-2 px-3;
}

.room-nav-box .room-participant-count {
  @apply text-blue-500;
}

.room-transfer-box-wrapper {
  @apply relative block overflow-y-auto w-full h-full py-2;
  max-height: 300px;
}

.room-transfer-box-wrapper .rct-title {
  @apply text-sm p-0;
}

.room-transfer-box-wrapper .rct-options .rct-option {
  padding: 0;
}

.room-transfer-box-wrapper .rct-node {
  max-width: 20rem;
}

@screen md {
  .room-transfer-box-wrapper .rct-node {
    max-width: 12rem;
  }
}

.room-transfer-box-wrapper .rct-node-clickable {
  @apply truncate;
}

.room-transfer-box-wrapper .rct-node-clickable:focus {
  outline: none;
  background: none;
}
