.badge {
  @apply inline-flex items-center px-2 py-0 rounded-md text-xs font-medium uppercase bg-secondary;
}

/* !important needed on badge colors because of TailWind 3.0 giving precedence to
   'bg-secondary' class in .badge
 */
.badge-success {
  @apply badge bg-sw-success !important;
}

.badge-warning {
  @apply badge bg-sw-warning !important;
}

.badge-danger {
  @apply badge bg-sw-danger !important;
}

.badge-info {
  @apply badge bg-sw-info !important;
}

.badge-sw-red {
  @apply badge bg-sw-red !important;
}

.badge-chat-new-messages {
  @apply absolute left-0 top-0 bg-sw-danger text-white rounded-full min-w-5 h-5 px-1 text-xs leading-5 !important;
}

@screen sm {
  .badge-chat-new-messages {
    @apply mt-1;
  }
}
