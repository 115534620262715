.react-tabs {
  @apply h-full;
}

.react-tabs__tab-list {
  @apply flex items-center text-contrast-m w-full overflow-auto;
}

.react-tabs__tab-list .react-tabs__tab {
  @apply text-center mr-3 py-2 px-4 cursor-pointer text-sm opacity-75 whitespace-nowrap;
}

.react-tabs__tab-list .react-tabs__tab:first-child {
  @apply ml-auto;
}

.react-tabs__tab-list .react-tabs__tab:last-child {
  @apply mr-auto pr-8;
}

@screen md {
  .react-tabs__tab-list .react-tabs__tab {
    @apply text-base;
  }
}

.react-tabs__tab-list .react-tabs__tab:hover {
  @apply text-contrast-h;
}

.react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  @apply text-contrast-h;
}

.react-tabs__tab-panel {
  @apply px-2;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  @apply overflow-auto;
  height: calc(100% - 40px);
}
