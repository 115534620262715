.tooltip-container {
  @apply p-0 border-none rounded-md z-40;
  max-width: 80vw;
  max-height: 40vh;
  background-color: var(--bg-element);
}

.tooltip-container.tooltip-min-w {
  min-width: 320px;
}

.tooltip-container.settings-tooltip {
  @apply p-0 bg-secondary rounded-lg overflow-hidden border-none;
  width: clamp(450px, 45vw, 600px);
}

@media (max-width: 767px) {
  .tooltip-container.tooltip-min-w,
  .tooltip-container.settings-tooltip {
    /* Center tooltip */
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
  }
  .tooltip-container.tooltip-min-w {
    position: fixed !important;
    max-width: 50vw;
    transform: translate3d(25vw, 10vh, 0px) !important;
  }
  .tooltip-container.settings-tooltip {
    transform: translate3d(10vw, 10vh, 0px) !important;
    max-height: 85vh;
    /* Not relevant on mobile */
    width: clamp(450px, 45vw, 600px);
  }
}

@media (max-width: 640px) {
  .tooltip-container.tooltip-min-w,
  .tooltip-container.settings-tooltip {
    /* Center tooltip */
    max-width: 80vw;
    transform: translate3d(10vw, 10vh, 0px) !important;
  }
}

@media (max-width: 384px) {
  .tooltip-container.tooltip-min-w {
    /* Full-width tooltip */
    max-width: 100vw;
    transform: translate3d(0, 10vh, 0px) !important;
  }
}

.tooltip-arrow[data-placement*='top'] {
  bottom: 1px;
}

.tooltip-arrow[data-placement*='top']::before {
  border-color: var(--pop-out-border) transparent transparent transparent;
}

.tooltip-arrow[data-placement*='top']::after {
  border-color: var(--pop-out) transparent transparent transparent;
}

.tooltip-arrow[data-placement*='bottom']::before {
  border-color: transparent transparent var(--pop-out-border) transparent;
}

.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent var(--pop-out) transparent;
}

.tooltip-arrow[data-placement*='left']::before {
  border-color: transparent transparent transparent var(--pop-out-border);
}

.tooltip-arrow[data-placement*='left']::after {
  border-color: transparent transparent transparent var(--pop-out);
}

.tooltip-arrow[data-placement*='right']::before {
  border-color: transparent var(--pop-out-border) transparent transparent;
}

.tooltip-arrow[data-placement*='right']::after {
  border-color: transparent var(--pop-out) transparent transparent;
}

.tooltip-container.in-call-tooltip {
  max-height: 70vh;
}

.tooltip-container.menu-tooltip {
  @apply bottom-bar-more p-2 bg-pop-out rounded-lg border border-solid border-pop-out-border;
}

/* "More" tooltip for mobile bottom bar buttons */
.bottom-bar-more .tooltip-arrow[data-placement*='top'] {
  bottom: 0;
}

/* Popper tooltips */

/* White on black default style */
.tooltip-text {
  /* `text-left` fixes alignment issues for elements wrapped with `text-center` */
  @apply invisible text-sm text-left text-sw-white whitespace-nowrap bg-sw-header rounded-md px-2 py-1 border border-gray-800 z-60;
}

/* Dark/light-mode style */
.tooltip-text.tooltip-theme {
  @apply text-contrast-h text-sm bg-pop-out;
}

.tooltip-text.tooltip-chat {
  @apply font-bold shadow-1 z-10;
  max-width: 325px;
}

.tooltip-text.tooltip-chat .tooltip-title {
  @apply w-full truncate;
}

.tooltip-text[data-show] {
  @apply visible;
}

.tooltip-text em {
  @apply opacity-50 pl-2 not-italic;
}

.tooltip-text.tooltip-theme em {
  @apply opacity-100 text-sm;
}

.tooltip-text.tooltip-chat em {
  @apply font-normal;
}

.tooltip-text .tooltip-subtitle {
  @apply opacity-50 text-sm;
}

.tooltip-text-arrow,
.tooltip-text-arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background: inherit;
  visibility: hidden;
}

.tooltip-text-arrow::before {
  @apply rounded-sm border border-b-0 border-r-0 border-gray-800;
  content: '';
}

.tooltip-text.tooltip-theme .tooltip-text-arrow,
.tooltip-text.tooltip-theme .tooltip-text-arrow::before {
  @apply bg-pop-out;
}

.tooltip-text[data-show] .tooltip-text-arrow::before {
  visibility: visible;
}

.tooltip-text[data-popper-placement^='bottom'] > .tooltip-text-arrow {
  top: -7px;
}
.tooltip-text[data-popper-placement^='bottom'] > .tooltip-text-arrow:before {
  transform: rotate(45deg);
}

.tooltip-text[data-popper-placement^='top'] > .tooltip-text-arrow {
  bottom: -7px;
}
.tooltip-text[data-popper-placement^='top'] > .tooltip-text-arrow:before {
  transform: rotate(225deg);
}

.tooltip-text[data-popper-placement^='left'] > .tooltip-text-arrow {
  right: -7px;
}
.tooltip-text[data-popper-placement^='left'] > .tooltip-text-arrow:before {
  transform: rotate(135deg);
}

.tooltip-text[data-popper-placement^='right'] > .tooltip-text-arrow {
  left: -7px;
}
.tooltip-text[data-popper-placement^='right'] > .tooltip-text-arrow:before {
  transform: rotate(-45deg);
}
