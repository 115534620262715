/**
  Tailwind will create all the utility classes to consume these variables.
  See tailwind.config.js
*/

:root {
  font-family: 'Inter', sans-serif;

  /* TODO: auto-generate shade & tint from base color */
  --sw-red: #f72a72;
  --sw-red-shade: #d92061;
  --sw-red-tint: #e12265;
  --sw-blue: #044ef4;
  --sw-blue-shade: #0b41bb;
  --sw-blue-tint: #0540c4;

  --sw-header: #101011;
  --bg-in-call-page: #1a1b1d; /* #141414 */

  /**
    * Shared Colors
    */
  --sw-white: #ffffff;
  --sw-black: #1b1d21;
  --sw-success: #00aa96;
  --sw-warning: #ffae11;
  --sw-danger: var(--sw-red);
  --sw-info: #c638f8;

  /**
    * Shadows
    */
  --shadow-level-1: rgba(0, 0, 0, 0.25);
}

.sw-theme-default,
.sw-theme-light {
  /**
    * Must keep these variables in sync with "dark"
    */

  --bg-app: #e0e3e9;

  --fg-contrast-high: #050910;
  --fg-contrast-medium: rgba(5, 9, 16, 0.5);
  --fg-contrast-low: rgba(0, 0, 0, 0.05);
  --placeholder: #616161;

  --button-primary-transparent: var(--sw-blue);
  --button-primary-transparent-hover: var(--sw-blue-shade);

  --button-secondary-transparent: var(--sw-red);
  --button-secondary-transparent-hover: var(--sw-red-shade);
  --button-secondary-transparent-border: #bdbdbd;

  /**
    * Background Colors
    */
  --bg-element: #f2f2f2;

  --sw-primary: #f0f2f5;
  --sw-primary-tint: #f6f9fc;

  --sw-secondary: #e0e3e9;
  --sw-highlight: #c2c8d1;

  --pop-out: #fefeff;
  --pop-out-border: #fefeff;

  /* Used to switch text/border colors between themes */
  --sw-selected: var(--sw-blue);
}

.sw-theme-dark {
  /**
    * Must keep these variables in sync with "light"
    */

  --bg-app: #050910;

  --fg-contrast-high: #eeeeee;
  --fg-contrast-medium: rgba(238, 238, 238, 0.5);
  --fg-contrast-low: rgba(255, 255, 255, 0.05);
  --placeholder: #959699;

  --button-primary-transparent: #eeeeee;
  --button-primary-transparent-hover: #dddddd;

  --button-secondary-transparent: var(--sw-red);
  --button-secondary-transparent-hover: var(--sw-red-shade);
  --button-secondary-transparent-border: #424242;

  /**
    * Background Colors
    */

  --sw-primary: #1d2128;
  --sw-primary-tint: #252a32;

  --sw-secondary: #21252c;
  --sw-highlight: #313841;

  --pop-out: #050910;
  --pop-out-border: #424242;

  --bg-element: #323539;

  /* Used to switch text/border colors between themes */
  --sw-selected: #6997ff;
}
