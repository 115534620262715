/* Input type overrides are needed to resolve precedence issues in Tailwind 3 */
input[type='text'],
input[type='email'],
input[type='string'],
input[type='number'],
textarea,
.sw-input {
  @apply appearance-none border rounded-md shadow-none w-full py-2 px-3 leading-tight;
  background: transparent;
  color: inherit;
}

input[type='pin'] {
  @apply appearance-none border rounded-md shadow-none py-2 px-3 leading-tight;
  background: transparent;
  color: inherit;
}

input[type='email']:disabled {
  @apply text-contrast-m cursor-not-allowed;
}

input::placeholder,
textarea::placeholder {
  color: var(--placeholder);
}

input:visited {
  box-shadow: none;
  border-color: none;
}

.sw-input-search {
  @apply h-10 px-8 !important;
}

.sw-input-error {
  @apply border-sw-danger;
}

.sw-error-hint {
  @apply text-sw-danger text-xs pt-1 sm:inline-block;
}

.sw-select {
  @apply appearance-none border rounded-md shadow-none w-full py-2 px-3 pr-8 leading-tight;
  background: transparent;
  border-color: inherit;
}

.tox-tinymce {
  @apply border rounded-md shadow-none;
}

/**
  * Style .sw-select option for Windows
  * https://github.com/signalwire/cloud-product/issues/999#issuecomment-746773806
*/
.block-primary .sw-select option {
  @apply bg-primary text-contrast-h;
}

.block-secondary .sw-select option {
  @apply bg-secondary text-contrast-h;
}

.sw-select-arrow {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2;
}

.toggle-dot {
  @apply w-3 h-3 ml-1 top-[2px] align-middle select-none transition duration-200 ease-in cursor-pointer absolute rounded-full shadow inset-y-0 left-0 bg-gray-900;
}

.toggle-medium .toggle-dot {
  @apply w-4 h-4;
  top: 2px;
}

.toggle-large .toggle-dot {
  @apply w-5 h-5;
  top: 4px;
}

input:checked ~ .toggle-dot {
  @apply bg-contrast-h;
  transform: translateX(100%);
}

.toggle-large input:checked ~ .toggle-dot {
  transform: translateX(120%);
}

.toggle-line {
  @apply w-8 h-4 bg-gray-400 rounded-full shadow-inner;
}

.toggle-medium .toggle-line {
  @apply w-10 h-5;
}

.toggle-large .toggle-line {
  @apply w-13 h-7;
}

input:checked ~ .toggle-line {
  @apply bg-green-500;
}

input[type='radio'] {
  @apply h-4 w-4 p-0 border-contrast-h rounded-full cursor-pointer;
}
input[type='radio']:checked {
  @apply text-sw-selected border-sw-selected;
}
/**
  * Workaround to have the svg "dot" within the
  * radio colored with SW blue or red in light and dark
*/
.sw-theme-light input[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23044ef4' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
.sw-theme-dark input[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%236997ff' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
