/* sw-btn-base should not be used in components.
   It was created to get around a Tailwind compilation issue that caused a circular
   dependency when adding pseudo-classes to .sw-btn (e.g. :disabled).
  */
.sw-btn-base {
  @apply font-medium py-2 px-4 rounded-md text-sm leading-tight border;
}

.sw-btn {
  @apply sw-btn-base;
}

/* !important needed on button colors because of TailWind 3.0's new base settings
   for: button, [type='button'], [type='reset'], [type='submit']
 */
.sw-btn-primary {
  @apply sw-btn-base bg-sw-blue border-sw-blue text-gray-200 !important;
}

.sw-btn-primary:hover,
.sw-btn-primary:focus,
.sw-btn-primary:active {
  @apply bg-sw-blue-shade border-sw-blue-shade !important;
}

.sw-btn-secondary {
  @apply sw-btn-base bg-sw-red border-sw-red text-gray-200 !important;
}

.sw-btn-secondary:hover,
.sw-btn-secondary:focus {
  @apply bg-sw-red-shade border-sw-red !important;
}

.sw-btn-tertiary {
  @apply sw-btn-base text-contrast-h !important;
}

.sw-btn-tertiary:hover {
  @apply bg-highlight !important;
}

.sw-btn-transparent {
  @apply sw-btn-base border-contrast-h text-contrast-h !important;
}

.sw-btn-primary.sw-btn-transparent {
  @apply bg-transparent !important;
  border-color: var(--button-primary-transparent) !important;
  color: var(--button-primary-transparent) !important;
}

.sw-btn-primary.sw-btn-transparent:hover,
.sw-btn-primary.sw-btn-transparent:focus,
.sw-btn-primary.sw-btn-transparent:active {
  border-color: var(--button-primary-transparent-hover) !important;
  color: var(--button-primary-transparent-hover) !important;
}

.sw-btn-secondary.sw-btn-transparent {
  @apply bg-transparent font-bold !important;
  border-color: var(--button-secondary-transparent-border) !important;
  color: var(--button-secondary-transparent) !important;
}

.sw-btn-secondary.sw-btn-transparent:hover,
.sw-btn-secondary.sw-btn-transparent:focus,
.sw-btn-secondary.sw-btn-transparent:active {
  color: var(--button-secondary-transparent-hover) !important;
}

.sw-btn:disabled,
.sw-btn-primary:disabled,
.sw-btn-secondary:disabled,
.sw-btn-tertiary:disabled {
  @apply opacity-40 cursor-not-allowed;
}

.sw-btn-xs {
  @apply text-xs px-2 py-1 leading-snug;
}

button:focus {
  outline-style: none;
}

button:focus-visible {
  outline-style: auto;
  outline-offset: 1px;
}

.sw-btn-icon-toggle {
  @apply w-8 h-8 p-0 text-sm;
}

.participant-row-icons .sw-btn-icon-toggle {
  @apply w-4 h-4 mr-2;
}

.participant-row-icons > .sw-btn-icon-toggle:last-of-type {
  @apply mr-0;
}

.participant-row-icons > .sw-btn-icon-toggle:first-of-type {
  @apply ml-2;
}

.sw-btn-icon-toggle.activated {
  @apply text-sw-red;
}

.sw-btn-icon-toggle:disabled {
  @apply opacity-40;
}

.sw-btn-icon-toggle:disabled,
button:disabled {
  @apply cursor-not-allowed;
}

.sw-btn-playback-control {
  @apply sw-btn-base w-10 h-10 mx-1 flex items-center justify-center border-transparent;
  background: transparent;
}

.sw-btn-playback-control:active {
  @apply bg-sw-red border-contrast-h;
}

button svg {
  pointer-events: none;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(82, 255, 82, 0.7);
  }

  70% {
    box-shadow: 0 0 0 1.2rem rgba(82, 255, 82, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(82, 255, 82, 0);
  }
}

.ptt-pulse {
  position: relative;
}

.ptt-pulse:before {
  display: block;
  border-radius: 50%;
  /* Must have at least 2px to get border radius working */
  height: 2px;
  width: 2px;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  background: transparent;
  box-shadow: 0 0 0 0 rgba(82, 255, 82, 1);
  animation: pulse-red 1s infinite;
}

.ptt-pulse svg {
  @apply text-teal-400;
  position: relative;
}

.sw-btn-close {
  @apply w-7 h-7 text-base;
}

.sw-btn-close:hover {
  @apply rounded-md bg-contrast-l;
}
