.shadow-level-1 {
  box-shadow: -4px 0px 8px var(--shadow-level-1);
}

.max-h-0 {
  max-height: 0;
}

.sw-transition-all {
  @apply transition-all ease-in-out duration-300;
}

/*
 * TODO: Review the usage of ".disabled" classNames
*/
.sw-disabled {
  @apply opacity-40 cursor-not-allowed;
}

/**
  Calc overlay box for the MCU
  - 110px is the header height + bottom bar height
**/
.aspect-ratio-16-9-wrapper {
  @apply relative w-full ease-in-out transition-all duration-300;
  margin: 0 auto;
  max-width: calc(((100vh - 110px) * (16 / 9)));
}

@screen landscape {
  .aspect-ratio-16-9-wrapper {
    max-width: calc(((95vh - 110px) * (16 / 9)));
  }
}

.aspect-ratio-16-9-wrapper.bottom-reduced-h {
  max-width: calc(((75vh - 110px) * (16 / 9)));
}

.aspect-ratio-16-9-wrapper.top-reduced-h {
  max-width: calc(((100vh - 190px) * (16 / 9)));
}

.aspect-ratio-16-9-wrapper.both-reduced-h {
  max-width: calc(((88vh - 300px) * (16 / 9)));
}

.aspect-ratio-16-9-wrapper .aspect-ratio-16-9-content {
  padding-bottom: 56.25%;
}

.center-box {
  box-shadow: 0px 4px 16px var(--shadow-level-1), 0px -4px,
    16px var(--shadow-level-1);
}

.fit-content {
  height: fit-content;
}

.overlay-mask {
  @apply absolute block h-0 max-h-full inset-0 z-20;
  padding-top: 56.25%;
  background: center center no-repeat;
  background-size: cover;
  pointer-events: none;
}

/* 2020-09-21 JMD
 * Firefox will cause an input to show a red border in the case that
 * it has a value and you clear it. We don't want this.
*/
:-moz-ui-invalid {
  box-shadow: none;
}

.checkbox-list-content {
  @apply grid grid-cols-2 overflow-y-auto;
  max-height: 300px;
}

@screen md {
  .checkbox-list-content {
    @apply grid-cols-3;
  }
}

hr {
  border-color: var(--bg-contrast-h);
}

#crisp-chatbox a[data-visible] {
  display: none !important;
}

/* Allow Crisp to use full screen at mobile device widths and set
   max-width and max-height for mobile devices having landscape
   widths in the 'md' range

   max-width offset = 2 x 25px (r & l margins)
   max-height offset = 80px + 25px (top value + bottom margin)
*/
@screen md {
  #crisp-chatbox div[data-visible][data-chat-status] {
    top: 80px !important;
    bottom: auto !important;
    right: 25px !important;
    left: auto !important;
    max-width: calc(100vw - 50px) !important;
    max-height: calc(100vh - 105px) !important;
  }

  /* Keep input field on-screen
     max-height offset = 100px + 105px (chat header height + offset above)
  */
  #crisp-chatbox div[data-visible][data-chat-status] > div + div {
    max-height: calc(100vh - 205px) !important;
  }
}

.participant-knock {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.participant-knock svg {
  transform: scale(1.2);
  transition: transform 200ms ease-in-out;
}

.participant-knock:hover svg {
  transform: scale(1.4);
}

@keyframes knocking {
  from {
    transform: scale(1.2) translateY(-2px);
  }
  50% {
    transform: scale(1.4) translateY(2px);
  }
  to {
    transform: scale(1.2) translateY(0px);
  }
}

.participant-knock.knocking svg {
  animation-name: knocking;
  animation-duration: 250ms;
  animation-iteration-count: infinite;
}

.flip-y {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.fullscreen {
  @apply absolute w-full h-full top-0 bottom-0 left-0 right-0;
}

.sw-page-content {
  @apply overflow-y-auto flex-1;
}

.server-error-svg .a {
  fill: none;
  stroke: var(--sw-blue);
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.audio-icon__bar {
  fill: white;
}

.audio-icon__bar:nth-child(1) {
  height: 10px;
  transform: translate(0px, 4px);
  animation: audioSynth1 300ms linear 333ms infinite alternate;
}

.audio-icon__bar:nth-child(2) {
  height: 15px;
  transform: translate(27px, 2px);
  animation: audioSynth2 300ms linear 17ms infinite alternate;
}

.audio-icon__bar:nth-child(3) {
  height: 20px;
  transform: translate(9px, -1px);
  animation: audioSynth3 300ms linear 514ms infinite alternate;
}

.audio-icon__bar:nth-child(4) {
  height: 10px;
  transform: translate(18px, 4px);
  animation: audioSynth4 250ms linear 13ms infinite alternate;
}

@keyframes audioSynth1 {
  0% {
    height: 10px;
    transform: translate(0px, 4px);
  }

  100% {
    height: 20px;
    transform: translate(0px, 0px);
  }
}

@keyframes audioSynth2 {
  0% {
    height: 10px;
    transform: translate(27px, 4px);
  }

  100% {
    height: 20px;
    transform: translate(27px, 0px);
  }
}

@keyframes audioSynth3 {
  0% {
    height: 10px;
    transform: translate(9px, 4px);
  }

  100% {
    height: 20px;
    transform: translate(9px, 0px);
  }
}

@keyframes audioSynth4 {
  0% {
    height: 10px;
    transform: translate(18px, 4px);
  }

  100% {
    height: 20px;
    transform: translate(18px, 0px);
  }
}

.talking-shadow {
  box-shadow: 0px 0px 15px var(--sw-red);
}

.microphone-meter-wrapper {
  @apply h-8 w-full flex items-center justify-between overflow-hidden pl-2;
  max-width: 200px;
}

.microphone-stick {
  @apply h-full rounded bg-contrast-m;
  flex: 0 0 8px;
}

.switch-device-wrapper .microphone-stick {
  flex: 0 0 5px;
}

.participant-options-floater,
.playback-controls-floater {
  @apply w-full;
}

@screen md {
  .playback-controls-floater {
    width: clamp(400px, 33%, 500px);
  }
}

@screen sm {
  .participant-options-floater {
    width: 600px;
    max-height: 460px;
  }
}

.clipeeze-preview {
  max-width: 96px;
  max-height: 64px;
  margin: 0 auto;
}

label {
  @apply font-medium leading-8 whitespace-nowrap;
}
