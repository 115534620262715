@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Medium.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Bold.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Bold.woff')
      format('woff');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Thin.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Thin.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Thin.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Thin.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Light.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Light.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Light.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Light.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Normal.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Normal.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Normal.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Normal.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Regular.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Regular.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Regular.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Regular.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Medium.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Medium.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Medium.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Medium.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-SemiBold.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-SemiBold.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-SemiBold.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-SemiBold.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Bold.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Bold.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Bold.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Bold.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-ExtraBold.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-ExtraBold.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-ExtraBold.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-ExtraBold.ttf')
      format('ttf');
}

@font-face {
  font-family: 'Neue Einstellung';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Black.woff2')
      format('woff2'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Black.woff')
      format('woff'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Black.eot')
      format('eot'),
    url('//cdn.signalwire.com/fonts/neue-einstellung/NeueEinstellung-Black.ttf')
      format('ttf');
}
