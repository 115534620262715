.administration-section-title {
  @apply flex items-center justify-between h-20;
}

.administration-section-content {
  @apply p-4 rounded-2xl;
}

@screen sm {
  .administration-section-content {
    @apply p-10;
  }
}

.administration-section-tabs-wrapper {
  @apply w-full border border-contrast-l rounded-xl overflow-hidden;
}

.administration-section-tab-label {
  @apply flex items-center py-3 px-2 border-l-4 border-transparent cursor-pointer;
}

.administration-section-tab-label:hover {
  @apply bg-contrast-l;
}

/* Includes styles to match .block-primary-tint */
.administration-section-item-title {
  @apply bg-primary-tint text-contrast-h flex items-center justify-between p-3 mb-3 rounded border border-contrast-l;
}

.administration-section-item-title > span {
  @apply truncate;
}

/* Always show action buttons on mobile */
.administration-section-item-title .item-actions {
  @apply flex ml-4;
}

@screen sm {
  /* Only show the admin actions on hover or focus on the row */
  .administration-section-item-title .item-actions {
    @apply hidden;
  }
}

/* focus-within needed for tabbing to the action icons */
.administration-section-item-title:hover .item-actions,
.administration-section-item-title:focus .item-actions,
.administration-section-item-title:focus-within .item-actions {
  @apply flex;
}

button.administration-user-role {
  min-width: 180px;
}
