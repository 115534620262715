.in-call-bottom-bar {
  @apply h-15 flex justify-between items-center pl-4 bg-sw-header;
}

.in-call-bottom-bar .element-desktop {
  @apply hidden;
}

.in-call-bottom-bar .element-mobile {
  @apply block;
}

.in-call-bottom-bar .element-mobile .tooltip-trigger {
  @apply inline-block;
}

.in-call-bottom-bar button:not(.sw-btn-secondary) {
  @apply w-10 h-15;
}

.in-call-bottom-bar button:not(.sw-btn-secondary):active,
.in-call-bottom-bar
  .tooltip-caret-wrapper:active
  button:not(.sw-btn-secondary) {
  @apply bg-gray-800;
}

@screen sm {
  /* hover effect on mobile was sticky, so only add it to desktop */
  .in-call-bottom-bar button:not(.sw-btn-secondary):hover,
  .in-call-bottom-bar
    .tooltip-caret-wrapper:hover
    button:not(.sw-btn-secondary) {
    @apply bg-gray-800;
  }
}

/* Override background if disabled */
.in-call-bottom-bar button:not(.sw-btn-secondary):disabled {
  @apply bg-transparent;
}

/* Fit outline in bottom-bar height for toggle buttons */
.in-call-bottom-bar .tooltip-wrapper button:focus-visible {
  outline-offset: -4px;
}

/* Fix for inconsistent button heights */
.in-call-bottom-bar .tooltip-wrapper {
  @apply w-10 h-8 mr-3 inline-block;
}

/* remove margin from buttons w/carets */
.in-call-bottom-bar .tooltip-caret-wrapper > .tooltip-wrapper {
  @apply mr-0;
}

.in-call-bottom-bar .tooltip-caret-wrapper .tooltip-trigger button {
  @apply w-auto px-1 -ml-1;
}

/* Fix for caret dimensions */
.in-call-bottom-bar .tooltip-caret-wrapper .tooltip-trigger .tooltip-wrapper {
  @apply w-auto h-auto inline-block;
}

@media (max-width: 400px) {
  .in-call-bottom-bar {
    @apply px-0 justify-around;
  }
  /* The Leave Room button needs to be small on mobile */
  .in-call-bottom-bar .sw-btn-secondary {
    @apply px-2 py-1;
  }
  .in-call-bottom-bar .tooltip-wrapper {
    @apply mr-0; /* Alow flexbox to space things on mobile */
  }
}

@media (min-width: 900px) {
  .in-call-bottom-bar {
    @apply h-13;
  }

  .in-call-bottom-bar .element-desktop {
    @apply block h-13;
  }

  .in-call-bottom-bar .element-mobile {
    @apply hidden;
  }

  .in-call-bottom-bar button:not(.sw-btn-secondary) {
    @apply w-10 h-13;
  }
}

/* Display icons in disabled state */
.in-call-bottom-bar .disabled button {
  @apply opacity-40 cursor-default;
}

.in-call-bottom-bar .disabled button:hover,
.in-call-bottom-bar .disabled .tooltip-caret-wrapper:hover button {
  @apply bg-transparent;
}

.in-call-bottom-bar .disabled .tooltip-text {
  @apply hidden;
}

/* Disable chevron animation */
.in-call-bottom-bar .disabled svg[data-icon='chevron-up'] {
  @apply mb-0;
}

.call-mcu-area {
  background-color: var(--bg-in-call-page);
}

.participant-box .primary-actions button {
  margin-right: 0.5rem;
}

.participant-box .sw-btn-icon-toggle {
  @apply w-4 h-6;
}

.participant-box .participant-row-actions {
  @apply shrink-0 flex flex-nowrap gap-2 items-center ml-auto h-8 px-3;
}

.switch-device-wrapper {
  @apply bg-primary text-contrast-h overflow-y-auto;
}

.switch-device-wrapper .device:hover {
  @apply bg-secondary;
}

.mcu-blurred-layer {
  background: radial-gradient(rgba(49, 49, 49, 0.89), black);
}

.user-menu.hand-raised {
  @apply border border-sw-success;
  box-shadow: inset 0 0 8px 1px var(--sw-success);
}

.user-menu.talking {
  @apply border border-sw-red;
  box-shadow: inset 0 0 8px 1px var(--sw-red);
}

.user-menu.sidebar {
  @apply border;
  box-shadow: inset 0 0 8px 1px;
}

.user-menu-gradient {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.user-menu .user-menu-items,
.local-video-wrapper .user-menu-items {
  @apply transition-all ease-in-out duration-300 user-menu-gradient;
}

.user-menu-indicator {
  @apply w-6 h-6 my-1 mx-3 text-white;
  position: fixed;
}

.user-menu-indicator.talking {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
}

.user-menu-items button,
.local-video-wrapper button {
  @apply w-6 h-6 m-0 p-0 border-0 text-sm text-white;
}

.user-menu-items .tooltip-wrapper,
.local-video-wrapper .tooltip-wrapper {
  @apply block my-1 mx-3;
}

.user-menu-items .sw-btn-icon-toggle.disabled,
.user-menu-items .sw-btn-icon-toggle.raise-hand.activated {
  @apply text-white;
}

#mcu-wrapper {
  @apply w-full h-full pointer-events-none;
}

video.mcu-video {
  @apply w-full max-w-full h-full max-h-full;
}

video.local-video {
  @apply w-full max-w-full h-full max-h-full;
  object-fit: fill;
}

.local-video-wrapper video.local-video {
  /* force draggable local video with max-h */
  max-height: 20vh;
}

.call-mcu-area {
  flex: 1;
}

.in-call-bottom-drawer {
  @apply transition-all ease-in-out duration-300 absolute z-10;
  height: 0;
}

.in-call-bottom-drawer.opened {
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  -webkit-box-shadow: inset -4px 2px 6px -1px rgba(0, 0, 0, 0.12);
  box-shadow: inset -4px 2px 6px -1px rgba(0, 0, 0, 0.12);
}

.in-call-bottom-drawer.opened .panel-wrapper {
  @apply flex md:inline-flex flex-col md:flex-row md:justify-center md:items-center mx-6 md:mx-auto text-sm md:p-2 mt-4 md:mt-0;
}

.in-call-bottom-drawer.opened .panel-border {
  @apply md:border-r border-b md:border-b-0 border-gray-500 h-0 md:h-5/6 w-full md:w-0 my-8 md:my-0 md:mx-10;
}

@screen md {
  .in-call-bottom-drawer {
    position: static;
  }
  .in-call-bottom-drawer.opened {
    height: 25vh;
    max-height: 250px;
  }
  .in-call-bottom-drawer.opened .panel-wrapper {
    min-height: 100%;
  }
}

@screen landscape {
  .in-call-bottom-drawer {
    position: absolute;
  }
  .in-call-bottom-drawer.opened {
    height: 100%;
  }
}

.in-call-bottom-drawer .react-tabs__tab-list .react-tabs__tab {
  @apply text-sm font-medium;
}

.in-call-bottom-drawer .react-tabs__tab-panel.react-tabs__tab-panel--selected {
  @apply text-center;
}

/* Right Drawer */
.in-call-right-drawer {
  @apply transition-all ease-in-out duration-300 w-0 flex flex-col items-center justify-between overflow-hidden;
}

/* Ensure that when mobile and landscape go full-width that they
   stack above the MCU contents, using z-40
*/
.in-call-right-drawer.opened {
  @apply absolute top-0 right-0 bottom-0 w-full z-40;
}

/* max-width matches const SIDE_DRAWER_MAX_WIDTH */
/* Breakpoint matches const SIDE_DRAWER_FULL_SCREEN_BREAKPOINT */
@media (min-width: 928px) {
  .in-call-right-drawer.opened {
    position: static;
    min-width: 200px;
    max-width: 300px;
    @apply w-1/3 z-10;
  }
}

@screen landscape {
  .in-call-right-drawer.opened {
    @apply w-full absolute z-40;
    max-width: inherit;
  }
}

.in-call-right-drawer .participant-list-wrapper {
  /* Show at least 4 participants (63px high) */
  min-height: 252px;
}

.participant-global-controls li:active {
  @apply bg-gray-600;
}

.participant-global-controls li:active button {
  @apply text-gray-800;
}

/* Video Options Layer */
.video-options {
  @apply absolute bottom-0 text-white w-full pt-5 z-30;
  background: radial-gradient(
    ellipse at center bottom,
    rgba(0, 0, 0, 1) 3%,
    rgba(0, 0, 0, 0) 65%
  );
}

.sw-clamp-lg {
  font-size: clamp(0.5rem, 2vw, 1.5rem);
  line-height: 1em;
}

.sw-clamp-base {
  font-size: clamp(0.75rem, 1vw, 1rem);
  line-height: 1.25em;
}
