.sw-header {
  background-color: var(--sw-header);
  color: var(--sw-white);
}

.sw-logo-full {
  max-width: 100%;
}

@screen md {
  .sw-logo-full {
    height: 32px;
    width: auto;
  }
}

.sw-header img.dynamic-logo {
  max-height: 40px;
  grid-row: 1;
  grid-column: 1;
}

.sw-header .room-name {
  max-width: 200px;
}

/* Ensure that wrapper is the full size of the button */
.sw-header .tooltip-trigger,
.sw-header .tooltip-trigger .tooltip-wrapper {
  @apply inline-block;
}
