/* Leave at top */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/**
  * Variables and global classes
  */
@import './variables.css';
@import './design-system.css';
@import './global.css';

/**
  * Components/Elements classes
  */
@import './layout.css';
@import './buttons.css';
@import './inputs.css';
@import './input-range.css';
@import './badges.css';
@import './tooltips.css';
@import './header.css';
@import './call.css';
@import './room-nav.css';
@import './rooms.css';
@import './modals.css';
@import './images.css';
@import './administration.css';

/**
 * Vendor overrides and customizations
 */
@import './vendor/react-tabs.css';
@import './vendor/react-toastify.css';

/*
 * Theme variation specific overrides
 */
@import './themes/light';
@import './themes/dark';

/* Leave at bottom */
@import 'tailwindcss/utilities';
