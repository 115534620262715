.sw-modal {
  @apply fixed w-full h-full top-0 left-0 flex items-center justify-center z-50;
}

.sw-modal .modal-overlay {
  @apply absolute w-full h-full bg-gray-900 opacity-50;
}

.sw-modal .modal-container {
  @apply relative w-11/12 mx-auto bg-primary rounded-lg shadow-lg overflow-hidden;
  max-height: 90vh;
}

@screen sm {
  .sw-modal .modal-container {
    @apply max-w-lg;
    width: 95%;
  }

  .sw-modal .modal-container.small {
    max-width: 375px; /* Match this to the room-nav-box rules in rooms.css*/
  }

  .sw-modal .modal-container.wide {
    max-width: initial;
    width: clamp(600px, 60%, 800px);
  }

  .sw-modal .modal-container.large {
    max-width: 1280px; /* Match this to the room-nav-box rules in rooms.css*/
  }
}

.room-preview-modal-wrapper .modal-overlay {
  @apply cursor-zoom-out;
}

.room-preview-modal-wrapper .modal-container > div {
  max-height: 90vh; /* Match .modal-container */
}
