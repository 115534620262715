.block-primary {
  @apply bg-primary text-contrast-h;
}

/* Override Preflight: https://tailwindcss.com/docs/preflight#border-styles-are-reset-globally */
.block-primary * {
  @apply border-contrast-m;
}

.block-primary-tint {
  @apply bg-primary-tint text-contrast-h;
}

.block-secondary {
  @apply bg-secondary text-contrast-h;
}

.block-secondary * {
  @apply border-gray-500;
}

.block-highlight {
  @apply bg-highlight text-contrast-h;
}

.block-highlight * {
  @apply border-gray-500;
}

.block-pop-out {
  @apply bg-pop-out text-contrast-h;
}
