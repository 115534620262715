.app-root {
  @apply w-screen flex flex-col;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--bg-app);
}

@screen md {
  .app-root {
    @apply flex-row;
  }
}

.app-main-content {
  @apply flex flex-col flex-1 overflow-auto;
}

.app-menu {
  min-width: 250px;
}

.app-menu-item {
  @apply flex items-center cursor-pointer text-contrast-h py-3 sm:py-4 px-2 mt-2 rounded-md h-4 landscape:h-4 sm:h-8;
}

.app-menu-item:disabled {
  @apply opacity-40 cursor-not-allowed;
}

.app-menu-item:hover:not(:disabled),
.app-menu-item:active {
  @apply bg-contrast-l;
}

.app-menu-item-label {
  @apply pl-2 leading-5 text-sm lg:text-lg landscape:text-sm landscape:lg:text-lg;
}

.app-menu-item-toggle {
  @apply flex items-center justify-start text-contrast-h py-4 mt-2 h-10 pl-2;
}

.app-menu-item-toggle .toggle-dot {
  @apply bg-gray-200;
}

.app-menu-item-toggle .toggle-line {
  @apply bg-sw-blue shadow-none;
}

.app-menu-item-toggle input:checked ~ .toggle-line {
  @apply bg-sw-red;
}

/* For displaying links in the BottomBarMore menu that appears for mobile users */
.more-menu-item-label {
  @apply text-sm;
}

.toggle-button-grid {
  @apply grid grid-cols-3 py-2 sm:flex sm:justify-between gap-2;
}

.settings-content {
  height: 70vh;
}
